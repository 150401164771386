$charcoal: #1c1e1f;
$snow: #f7fafb;
$coral: #ff9662;
$jet: #b9ccd6;
$poppins: "Poppins", sans-serif;
//Toggle UI
@mixin toggle-displayer {
  position: relative;
  display: block;
  width: 300px;
  height: 50px;
  margin: 35px auto;
  cursor: default;
  text-transform: uppercase;

  * {
    transition: all 200ms linear;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    &:checked + .base-color {
      .toggle-slider {
        margin-left: 43.5%;
      }

      .cash {
        color: $charcoal;
      }

      .token {
        color: $snow;
      }
    }
  }

  input[type="checkbox"] + .base-color {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 150ms linear;
    background: $jet;
    border-radius: 32px;

    .toggle-slider {
      display: block;
      position: absolute;
      content: " ";
      background: rgb(53, 53, 53);
      width: 55%;
      &.disabled {
        width: 100%;
        color: white;
        text-align: center;
        text-justify: center;
        line-height: 45px;
      }
      height: 100%;
      transition: margin 300ms linear;
      border-radius: 32px;
      height: 46px;
      top: 2px;
      left: 2px;
      // background-image: linear-gradient(#21323a 0%, #324853 100%);
    }

    .cash {
      color:  $snow;
    }
  }

  .cash,
  .token {
    display: block;
    position: absolute;
    width: 50%;
    color: $charcoal;
    line-height: 50px;
    text-align: center;
  }

  .cash {
    left: 0px;
  }

  .token {
    right: 0px;
  }
}

#infoSpend, #infoConsumption, #infoCo2 {
  text-align: center;
  font-size: 1.1em;
  font-weight: 700;
  color: #007cbd;

  p {
    color: black;
    font-weight: 400;
  }
}

@mixin clover-spinner {
  .loader-el {
    --origin-x: center;
    --origin-y: center;
  
    width: 8px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    animation: spin 0.6s linear infinite;
  
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      animation: spin 1s ease-out infinite;
      transform-origin: var(--origin-x) var(--origin-y);
      background-color: dodgerblue;
    }
  
    &:nth-child(1) {
      &::before {
        --origin-y: -100%;
      }
  
      &::after {
        --origin-y: 200%;
      }
    }
  
    &:nth-child(2) {
      &::before {
        --origin-x: -100%;
      }
  
      &::after {
        --origin-x: 200%;
      }
    }
  }
  
  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
}