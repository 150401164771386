.total-headers {
    padding-left: 1rem!important; 
    padding-right: 1rem!important;
}

.park-card {
    max-height: 375px;
    min-height: 375px;
}

.filter-title {
    // margin-bottom: 32px;
    margin-bottom: 20px;
}

.park-filter-group {
    display: flex; 
    flex-direction: column;
    // text-align: center;
}

.park-map-card {
    text-align: center; 
    margin-bottom: 32px;
}

.chart-size {
    height: 280px; 
    width: 280px;
}

#buildingsPieCharts {
    display: flex;
    justify-content: space-around;
}

.chart-group {
    width: 33%;
    p {
        color: #666666;
    }
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
    margin-bottom: 0;
}

.table-responsive {
    scrollbar-gutter: stable both-edges;
}