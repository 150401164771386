/* All the default sidebar styles */
/* toggle button settings */
/* Note: this disables the navbar-toggle class's hide function. set visibility with the boostrap visible/hidden classes */
/* Note: this also disables the navbar-header class positioning settings in small screens. */
.navbar.navbar-static .navbar-header {
    float: left;
}

.navbar .navbar-toggle.toggle-left {
    float: left;
    margin-left: 15px;
}

.navbar .navbar-toggle.toggle-right {
    float: right;
    margin-right: 15px;
}

.navbar .navbar-toggle.toggle-sidebar, [data-toggle="sidebar"] {
    display: block;
}

/* sidebar settings */
.sidebar {
    position: fixed;
    display: block;
    top: 0px;
	width:300px;
    bottom: 0;
    z-index: 9999; 
}
.sidebar-left {
    left: 0;
}
.sidebar-right {
    right: 0;
}
/* css to override hiding the sidebar according to different screen sizes */
.row .sidebar.sidebar-left.sidebar-xs-show {
    left: 0;
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    -moz-transform: translate(0,0);
    transform: translate(0,0);
}

/*right sidebar is untested */
.row .sidebar.sidebar-right.sidebar-xs-show {
    right: 0;
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    -moz-transform: translate(0,0);
    transform: translate(0,0);
}

@media (min-width: 768px) {
    .row .sidebar.sidebar-left.sidebar-sm-show {
        left: 0;
        -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        -moz-transform: translate(0,0);
        transform: translate(0,0);
    }

    /*right sidebar is untested */
    .row .sidebar.sidebar-right.sidebar-sm-show {
        right: 0;
        -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        -moz-transform: translate(0,0);
        transform: translate(0,0);
    }
}

@media (min-width: 992px) {
    .row .sidebar.sidebar-left.sidebar-md-show {
        left: 0;
        -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        -moz-transform: translate(0,0);
        transform: translate(0,0);
    }

    .row .sidebar.sidebar-right.sidebar-md-show {
        right: 0;
        -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        -moz-transform: translate(0,0);
        transform: translate(0,0);
    }
}

@media (min-width: 1170px) {
    .row .sidebar.sidebar-left.sidebar-lg-show {
        left: 0;
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
    }

    .row .sidebar.sidebar-right.sidebar-lg-show {
        right: 0;
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
    }
}

/* animation class - optional: without it the sidebar would just pop in and out*/
.sidebar-animate {
    -webkit-transition: -webkit-transform 300ms ease;
    -moz-transition: -moz-transform 300ms ease;
    -o-transition: transform 300ms ease;
    transition: transform 300ms ease;
}

/* Left panel positioning classes */
.sidebar.sidebar-left {
    -webkit-transform: translate(-100%,0);
    -moz-transform: translate(-100%,0);
    -ms-transform: translate(-100%,0);
    -o-transform: translate(-100%,0);
    transform: translate(-100%,0);
}

    .sidebar.sidebar-left.sidebar-open {
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
    }

.sidebar.sidebar-right {
    -webkit-transform: translate(100%,0);
    -moz-transform: translate(100%,0);
    -ms-transform: translate(100%,0);
    -o-transform: translate(100%,0);
    transform: translate(100%,0);
}

.sidebar.sidebar-right.sidebar-open {
	-webkit-transform: translate(0,0);
	-moz-transform: translate(0,0);
	-ms-transform: translate(0,0);
	-o-transform: translate(0,0);
	transform: translate(0,0);
	box-shadow: none;
}
