@use '../../../assets/scss/elements/index.scss' as elements;

.recharts-surface {
  text {
    user-select: none;
  }
  overflow: visible;
}

.chart-container, .slick-list {
  overflow: visible;
}

.slick-arrow::before {
  color: rgb(55, 54, 65) !important;
}

.slick-prev::before {
  // font-family: Arial, Helvetica, sans-serif !important;
  font-size: 47px !important;
  content: "‹" !important;
}
.slick-next::before {
  // font-family: Arial, Helvetica, sans-serif !important;
  font-size: 47px !important;
  content: "›" !important;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 56px;
  height: 20px;
  padding: 3px;
  background-color: white;
  border-radius: 18px;
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  top: -30px;
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}

.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked ~ .switch-label {
  background: #47a8d8;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}

.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}

.switch-handle:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #f9f9f9;
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  background-image: -webkit-linear-gradient(top, #eeeeee, white);
  background-image: -moz-linear-gradient(top, #eeeeee, white);
  background-image: -o-linear-gradient(top, #eeeeee, white);
  background-image: linear-gradient(to bottom, #eeeeee, white);
}

.switch-input:checked ~ .switch-handle {
  left: 33px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.ss-main .ss-multi-selected .ss-values .ss-value {
  background-color: #e6e7e8 !important;
}

.panel-title1 {
  text-align: center;
  background-color: white;
  border-radius: 6px;
  border-bottom: solid 1px #031B4E;
  color: black;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 4px 8px -2px rgb(9 30 66 / 25%), 0px 0px 0px 1px rgb(9 30 66 / 8%);

  a {
    color: black;
    &:hover {
      color: black;
    }
  }
}


.loader_chartHydro, .loader_chartTemp, .loader_chartHum, .loader_chartCo2, .loader_chartBrightness, .loader_chartMotion,
.loader_chartSoundPeak, .loader_chartSoundAvg, .loader_chartCov, .loader_chartBatterie {
	position: relative;
	margin: auto;
	width: 2vmin;
	height: 31vmin;
}

.chart-export-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:active {
    color: white !important;
  }

  .badge {
     margin-left: .5rem;
  }
}

.metrology-buttons{
  display: flex;
  justify-content: space-around;

  button {
    font-size: 0.65rem;
  }
}
@include elements.clover-spinner()